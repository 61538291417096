<template>
  <div class="first-time-dialog-content">
    <div class="blocktrade">
      <div class="blocktrade-content">
        <div class="blocktrade-item">
          <div class="item-description">
            <img
              v-if="image"
              :src="image"
              class="spirit-type"
              height="58"
              width="58"
            />
            <div>
              <h3 class="bottle-name">{{ productName }}</h3>
              <div class="ref-blocktrade">
                <div class="ticker">
                  <button class="ticker-maitre">{{ ticker }}</button>
                </div>
                <span class="ref">{{ reference }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider />
    </div>

    <div class="first-time-dialog-content-body">
      <div class="link-explain-bloc">
        <p class="link">
          {{ $t('product_page.orders.blocktrade_unique_link') }}
        </p>
        <div class="input-group">
          <input
            :value="'https://thexchangeplatform.io/product/order/informations?token=' + getCreateOrderDialog"
            class="input-transparent"
            readonly
            type="text"
          />
          <button class="btn-copied" @click="copyLink">
            <span v-if="!copied">{{ $t('commons.copy_1') }}</span>
            <span v-if="copied">{{ $t('commons.copy_2') }}</span>
            <img
              v-if="copied"
              alt="Checked"
              class="checked"
              src="@/assets/svg/v2/checked.svg"
            />
          </button>
        </div>
        <div>
          <p class="lock-info">
            {{ $t('product_page.orders.blocktrade_secure_transaction') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    ticker: {
      type: String,
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
      image: null,
    }
  },
  mounted() {
    if (this.getProduct.files_attachments) {
      if (this.getProduct.files_attachments.length > 0) {
        this.image = this.getProduct.files_attachments[0];
      }
    }
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
      getProduct: 'getProduct',
    }),
  },
  methods: {
    closeBlockTrade() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
    }),
    ...mapActions({
      agreeOrder: 'agreeOrder',
      getClient: 'getAccountDetails',
    }),
    copyLink() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          'https://thexchangeplatform.io/product/order/informations?token=' + this.getCreateOrderDialog)
          .then(() => {
            this.$emit('link-copied')
            this.copied = true
          })
      } else {
        const el = document.createElement('textarea')
        el.value = this.url
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      }
    },
    async acceptConditions() {
      const payload = {
        type: this.type,
      }

      await this.agreeOrder(payload).then(() => {
        this.getClient()
        this.$emit('agree', true)
      }).catch((error) => {
        if (error.response.status === 406) {
          this.$router.push('/register');
        }
      })
    },
    getPhoto(photo) {
      return require(photo)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.ref-blocktrade {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .ticker {
    .ticker-maitre {
      background: transparent;
      border: 1px solid grey;
      padding: 2px 6px;
      border-radius: 5px;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

span.ref {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.btn-copied {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 100px;
  padding: 14px 20px;
  border-radius: 15px;
  background: $blue;
  color: white;
  border: none;
  cursor: pointer;
}

.blocktrade-content {
  margin-bottom: 15px;
  margin-top: 10px;

  .blocktrade-item {
    display: flex;
    align-items: center;

    .item-description {
      display: flex;
      align-items: center;

      img.spirit-type {
        height: auto;
        width: 58px;
        margin-right: 10px;
      }

      .bottle-name {
        color: white;
        margin-bottom: 10px;
      }
    }
  }
}

div.blocktrade {
  padding: 0 50px;
}

p.link-header {
  margin: 20px;
  margin-left: 0;
  color: rgba(255, 255, 255, 0.6);
}

p.link {
  margin-bottom: 20px;
  color: white !important;
}

p.lock-info {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #84cc16 !important;
}

.first-time-dialog .first-time-dialog-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 750px !important;
}

.block-order-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue !important;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  background-size: cover;
}

.block-order-title img {
  transform: scale(1.3);
  transition: transform 0.3s ease;
}

.first-time-dialog .first-time-dialog-content .first-time-dialog-content-header {
  background: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-time-dialog {
  z-index: 5;
  position: relative;

  .first-time-dialog-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333333;
    max-height: 684px;
    max-width: 600px;
    border-radius: 20px;
    border: 1px solid $border;

    .first-time-dialog-content-header {
      margin-left: 50px;
      margin-right: 50px;
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start !important;
      align-items: baseline !important;
      border-radius: 20px 20px 0px 0px;

      .block-order-title {
        margin-right: 10px;
      }

      h1 {
        margin: 0;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 23px;
        margin-top: 33px;
        background: #84cc16;
        width: 38px;
        height: 38px;
      }
    }

    .first-time-dialog-divider {
      border-color: $border;
    }

    .first-time-dialog-content-body {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .accept-bloc {
      display: flex;
      justify-content: center;
      margin: 30px auto;
      width: 116px;
    }
  }
}

.link-explain-bloc {
  padding: 0 50px;
  margin-top: 15px;

  div {
    h3 {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.first-time-dialog-content-body::-webkit-scrollbar {
  width: 5px;
}

.first-time-dialog-content-body::-webkit-scrollbar-track {
  background: #333333;
}

.first-time-dialog-content-body::-webkit-scrollbar-thumb {
  background-color: #818181;
  border-radius: 10px;
}

.first-time-dialog-content-body::-webkit-scrollbar-thumb:hover {
  background-color: $base-color;
}

.buyer-title,
.seller-title {
  color: #84cc16;
  font-size: 24px;
  font-weight: 600;
}

.seller-title {
  color: #f43f5e;
}

.sellerArrow {
  background: #f43f5e !important;
}

.sellerBg {
  border-radius: 20px 20px 0px 0px !important;
  background: linear-gradient(
    180deg,
    rgba(244, 63, 94, 0.16) 0%,
    rgba(244, 63, 94, 0) 100%
  ) !important;
}

.input-group {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;

  input {
    width: 100%;
  }
}

.transparent {
  margin-left: auto;
  border-radius: 76px;
  border: 1px solid $border;
  background: #444;
  width: 38px;
  height: 38px;
  background: transparent !important;
}

.checked {
  margin-left: 5px;
}

@media (max-width: 1200px) {
  .blocktrade {
    padding: 0 30px;
  }

  .link-explain-bloc {
    padding: 0 30px;
  }

  .first-time-dialog-content {
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .ref-blocktrade {
    flex-direction: column;
    align-items: center;
  }

  .btn-copied {
    width: auto;
    margin-left: 0;
    padding: 10px 15px;
  }

  .blocktrade {
    padding: 0 15px;
  }

  .link-explain-bloc {
    padding: 0 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .first-time-dialog-content {
    width: 90%;
    max-width: 90%;
  }

  .first-time-dialog-content-header {
    margin-left: 20px;
    margin-right: 20px;
  }

  .first-time-dialog-content-body {
    flex-direction: column;
  }
}

@media (max-width: 480px) {

  .input-group {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  div.blocktrade {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 50px;
  }

  p.link-header {
    margin: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .blocktrade-item {
    flex-direction: column;
    align-items: flex-start;

    .item-description {
      flex-direction: column;
      align-items: flex-start;
    }

    img.spirit-type {
      width: 48px;
      margin-right: 5px;
    }

    .bottle-name {
      margin-bottom: 5px !important;
      margin-left: 40px;
    }
  }

  .link-header, .link, .lock-info {
    font-size: 14px;
  }

  .btn-copied {
    padding: 8px 10px;
  }

  .block-order-title {
    width: 45px;
    height: 45px;
  }
}
</style>