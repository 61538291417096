const colors = [
  '#2667ff',
  '#fff500',
  '#9500b2',
  '#00f0ff',
  '#ffb300'
];

export default function selectRandomColor(tags){
  const alreadyExist = [];
  let color;

  tags.forEach(el => {
    alreadyExist.push(el.color)
  });

  colors.forEach(c => {
    if (!alreadyExist.includes(c)) {
      color = c;
      return c;
    }
  })

  return color;
}