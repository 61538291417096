<template>
  <div class="dialog">
    <div class="sell-order">
      <div class="sell-order-bottle" :class="{ removePadding: !firstImage }">
        <img
          v-if="firstImage"
          :alt="getProduct.title"
          :src="firstImage"
          height="58"
          width="58"
        />
        <img
          v-else
          :src="require('@/assets/svg/v2/bottle.svg')"
          alt="Product not found image"
          class="empty-bottle-picture"
          height="58"
          width="58"
        />
        <div>
          <h2>{{ getProduct.title }}</h2>
          <p>{{ getProduct.ticker }}</p>
        </div>
      </div>
      <form
        action=""
        enctype="multipart/form-data"
        @submit.prevent="createBlockTrade"
      >
        <div class="form-input-bloc-header">
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_price') }}</h3>
            <el-input
              v-model="blockTradeForm.price"
              :class="{ errorInput: blockTradeFormRegex.price }"
              class="sell-order-input"
              placeholder="50-30000"
            />
          </div>
          <div class="input-line">
            <h3>{{ $t('product_page.orders.sell_quantity') }}</h3>
            <el-input
              v-model="blockTradeForm.quantity"
              :class="{ errorInput: blockTradeFormRegex.quantity }"
              class="sell-order-input"
              max="10"
              min="1"
              placeholder="1"
              type="number"
              disabled
            />
          </div>
        </div>
        <div class="package-dimensions">
          <div class="form-input-bloc margin-block-form">
            <h3>{{ $t('product_page.orders.weight_unit') }}</h3>

            <el-select
              v-model="blockTradeForm.weight_unit"
              class="generic-border"
              effect="dark"
            >
              <el-option
                v-for="unit in getLists.unit_of_measurement_weight"
                :key="unit.value"
                :label="unit.label"
                :value="unit.value"
              />
            </el-select>
          </div>
          <div class="form-input-bloc">
            <h3>
              {{ $t('product_page.orders.sell_weight') }} ({{
                blockTradeForm.weight_unit
              }})
            </h3>
            <el-input
              v-model="blockTradeForm.weight"
              :class="{ errorInput: blockTradeFormRegex.length }"
              class="sell-order-input"
              max="20"
              min="1"
              placeholder="1"
              type="number"
            />
          </div>
        </div>
        <div class="package-dimensions">
          <div class="form-input-bloc margin-block-form">
            <h3>{{ $t('product_page.orders.size_unit') }}</h3>
            <el-select
              v-model="blockTradeForm.size_unit"
              class="generic-border"
              effect="dark"
            >
              <el-option
                v-for="unit in getLists.unit_of_measurement_dimensions"
                :key="unit.value"
                :label="unit.label"
                :value="unit.value"
              />
            </el-select>
          </div>
          <div class="form-input-bloc margin-block-form">
            <h3>
              {{ $t('product_page.orders.length') }} ({{
                blockTradeForm.size_unit
              }})
            </h3>
            <el-input
              v-model="blockTradeForm.package_dimensions.length"
              :class="{ errorInput: blockTradeFormRegex.length }"
              class="sell-order-input"
              max="150"
              min="10"
              type="number"
            />
          </div>
          <div class="form-input-bloc margin-block-form">
            <h3>
              {{ $t('product_page.orders.width') }} ({{
                blockTradeForm.size_unit
              }})
            </h3>
            <el-input
              v-model="blockTradeForm.package_dimensions.width"
              :class="{ errorInput: blockTradeFormRegex.width }"
              class="sell-order-input"
              max="150"
              min="10"
              type="number"
            />
          </div>
          <div class="form-input-bloc">
            <h3>
              {{ $t('product_page.orders.height') }} ({{
                blockTradeForm.size_unit
              }})
            </h3>
            <el-input
              v-model="blockTradeForm.package_dimensions.height"
              :class="{ errorInput: blockTradeFormRegex.height }"
              class="sell-order-input"
              max="150"
              min="10"
              type="number"
            />
          </div>
        </div>
        <div class="form-input-bloc">
          <h3>{{ $t('product_page.orders.sell_pictures') }}</h3>
          <input
            ref="file"
            :multiple="true"
            accept="image/jpg, image/jpeg, image/png"
            class="custom-file-input"
            name="document"
            type="file"
            @change="files"
          />
          <div v-if="selectedFiles.length > 0">
            <div class="image-previews">
              <div
                v-for="(file, index) in selectedFiles"
                :key="index"
                class="image-preview"
                @mouseenter="showDeleteButton(index)"
                @mouseleave="hideDeleteButton(index)"
              >
                <img
                  :src="file.preview"
                  alt="Selected Image"
                  class="image-preview"
                />

                <button class="delete-button" @click="removeFile(index)">
                  <img
                    id="delete"
                    :src="require(`@/assets/svg/v2/orders/trash.svg`)"
                    alt="Delete"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <span
          v-if="blockTradeFormRegex.file"
          class="error"
          style="margin: 10px 0"
          >{{ $t('product_page.orders.regex_pictures_number') }}</span
        >
      </form>
      <button
        :disabled="isLoading"
        v-loading="isLoading"
        class="create-order-btn generic-btn"
        @click.prevent="createBlockTrade"
      >
        {{ $t('product_page.orders.sell_create_order') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapActions, mapGetters } from 'vuex'
import m from 'moment'

export default {
  props: {},
  data() {
    return {
      selectedFiles: [],
      isLoading: false,
      blockTradeForm: {
        price: null,
        weight_unit: 'KGS',
        size_unit: 'CM',
        quantity: 1,
        weight: null,
        package_dimensions: {
          length: null,
          width: null,
          height: null,
        },
        endDate: false,
        date: null,
        time: null,
      },
      blockTradeFormRegex: {
        price: false,
        quantity: false,
        endDate: false,
        date: false,
        time: false,
        dateBeforeNow: false,
        length: false,
        width: false,
        height: false,
        file: false,
        weight: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog',
      getProduct: 'getProduct',
      getLists: 'getLists',
    }),
    firstImage() {
      if (this.getProduct.files_attachments) {
        return this.getProduct.files_attachments[0]
      }
      return ''
    },
    sellDateFormat() {
      if (
        this.blockTradeForm.date !== null &&
        this.blockTradeForm.time !== null
      ) {
        return `${this.blockTradeForm.date} ${this.blockTradeForm.time}`
      } else {
        return m().add(1, 'years').format('YYYY-MM-DD HH:mm')
      }
    },
  },
  watch: {
    'blockTradeForm.time': function () {
      if (this.blockTradeForm.time) {
        let slashCount = 0
        for (var i = 0; i < this.blockTradeForm.time; i++) {
          if (this.blockTradeForm.time[i] === ':') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.blockTradeForm.time = ''
        }

        if (this.blockTradeForm.time.length === 2 && slashCount === 0) {
          this.blockTradeForm.time += ':'
        }
      }
    },
    sellDateFormat() {
      const now = m().add(1, 'hours').format('YYYY-MM-DD HH:mm')

      if (this.blockTradeForm.date && this.blockTradeForm.time) {
        const isBeforeNow = m(
          `${this.blockTradeForm.date} ${this.blockTradeForm.time}`
        ).isBefore(now)

        if (isBeforeNow) {
          this.blockTradeForm.time = null
          this.blockTradeForm.date = null
        } else {
          this.blockTradeFormRegex.dateBeforeNow = false
        }
      }
    },
  },
  methods: {
    disabledDate(time) {
      const today = new Date()
      const threeMonthsFromNow = new Date(
        today.getFullYear(),
        today.getMonth() + 3,
        today.getDate()
      )
      return (
        time.getTime() < today.getTime() ||
        time.getTime() > threeMonthsFromNow.getTime()
      )
    },
    ...mapActions({
      createOrder: 'createBuyOrder',
    }),
    getWeight(bottleSize, weightUnit) {
      const weightItem = this.getLists.minimum_weight.find(
        (item) => item.bottle_size === parseInt(bottleSize)
      )
      if (!weightItem) return null

      switch (weightUnit) {
        case 'KGS':
          return weightItem.weight / 1000
        case 'LBS':
          return weightItem.weight / 453.59237
        case 'OZS':
          return weightItem.weight * 0.035274
        default:
          return null
      }
    },
    checkDuplicateFiles() {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        for (let j = i + 1; j < this.selectedFiles.length; j++) {
          if (this.selectedFiles[i].preview === this.selectedFiles[j].preview) {
            return true
          }
        }
      }
      return false
    },
    ...mapActions({
      createBlockTradeActions: 'createBlockTrade',
    }),
    async createBlockTrade() {
      const regex_price = /^[1-9][0-9]{0,5}$/
      const quantity_regex = /^[0-9]{1,3}$/
      const dimensions_regex = /^[1-9][0-9]{0,3}$/
      const hourRegex = /^(?:[0-9]{2}):(?:[0-9]{2})$/

      this.isLoading = true;

      const payload = {
        isbn: this.getProduct.isbn,
        quantity: this.blockTradeForm.quantity,
        price: this.blockTradeForm.price,
        weight_unit: this.blockTradeForm.weight_unit,
        size_unit: this.blockTradeForm.size_unit,
        weight: this.blockTradeForm.weight,
        countdown: `${this.blockTradeForm.date} ${this.blockTradeForm.time}`,
        package_dimensions: this.blockTradeForm.package_dimensions,
      }

      if (this.blockTradeForm.endDate) {
        if (m(payload.countdown).isValid()) {
          this.blockTradeFormmRegex.countdown = false

          const now = m().toString()
          const countdownAddHour = m(payload.countdown, 'YYYY-MM-DD HH-mm').add(
            1,
            'h'
          )

          if (m(now).isBefore(countdownAddHour)) {
            this.blockTradeFormRegex.dateBeforeNow = false
          } else {
            this.blockTradeFormRegex.dateBeforeNow = true
          }
        } else {
          this.blockTradeFormRegex.dateBeforeNow = true
        }
      } else {
        delete payload.countdown
      }

      if (
        regex_price.test(this.blockTradeForm.price) &&
        parseInt(this.blockTradeForm.price) >= 50 &&
        parseInt(this.blockTradeForm.price) <= 30000
      ) {
        this.blockTradeFormRegex.price = false
      } else {
        this.blockTradeFormRegex.price = true
      }

      if (
        quantity_regex.test(this.blockTradeForm.quantity) &&
        parseInt(this.blockTradeForm.quantity) >= 1
      ) {
        this.blockTradeFormRegex.quantity = false
      } else {
        this.blockTradeFormRegex.quantity = true
      }

      if (
        dimensions_regex.test(this.blockTradeForm.package_dimensions.length)
      ) {
        this.blockTradeFormRegex.length = false
      } else {
        this.blockTradeFormRegex.length = true
      }

      if (dimensions_regex.test(this.blockTradeForm.package_dimensions.width)) {
        this.blockTradeFormRegex.width = false
      } else {
        this.blockTradeFormRegex.width = true
      }

      if (
        dimensions_regex.test(this.blockTradeForm.package_dimensions.height)
      ) {
        this.blockTradeFormRegex.height = false
      } else {
        this.blockTradeFormRegex.height = true
      }

      if (this.getProduct.bottle_size === 500 || this.getProduct.bottle_size === 700 || this.getProduct.bottle_size === 750) {
        if (payload.package_dimensions.length < 10 || payload.package_dimensions.width < 10 || payload.package_dimensions.height < 10) {
          this.blockTradeFormRegex.length = true
          this.blockTradeFormRegex.width = true
          this.blockTradeFormRegex.height = true
        } else {
          this.blockTradeFormRegex.length = false
          this.blockTradeFormRegex.width = false
          this.blockTradeFormRegex.height = false
        }
      }

      if (
        parseInt(this.blockTradeForm.weight) <
        this.getWeight(
          this.getProduct.bottle_size,
          this.blockTradeForm.weight_unit
        ) *
          this.blockTradeForm.quantity
      ) {
        this.blockTradeFormRegex.weight = true
      } else {
        this.blockTradeFormRegex.weight = false
      }

      if (this.blockTradeForm.time) {
        if (hourRegex.test(this.blockTradeForm.time)) {
          this.blockTradeFormRegex.time = false
        } else {
          this.blockTradeFormRegex.time = true
        }
      }

      if (this.checkDuplicateFiles()) {
        this.blockTradeFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.duplicate_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else if (
        this.selectedFiles.length < Number(this.getProduct.allowed_min_pictures) ||
        this.selectedFiles.length > 8
      ) {
        this.blockTradeFormRegex.file = true
        ElMessage({
          message: this.$t('product_page.orders.max_min_pictures'),
          type: 'error',
          grouping: true,
          duration: 5000,
        })
      } else {
        this.blockTradeFormRegex.file = false
      }

      if (
        !this.blockTradeFormRegex.quantity &&
        !this.blockTradeFormRegex.price &&
        !this.blockTradeFormRegex.file &&
        !this.blockTradeFormRegex.dateBeforeNow &&
        !this.blockTradeFormRegex.length &&
        !this.blockTradeFormRegex.width &&
        !this.blockTradeFormRegex.height &&
        !this.blockTradeFormRegex.weight &&
        !this.blockTradeFormRegex.time
      ) {
        const formData = new FormData()
        formData.append('isbn', payload.isbn)
        formData.append('quantity', payload.quantity)
        formData.append('full_size', payload.full_size)
        formData.append('price', payload.price)
        formData.append('weight', payload.weight)
        formData.append('weight_unit', payload.weight_unit)
        formData.append('size_unit', payload.size_unit)
        formData.append('bottle_size', this.getProduct.bottle_size)
        if (payload.countdown != undefined) {
          formData.append('countdown', payload.countdown)
        }
        formData.append(
          'package_dimensions',
          JSON.stringify(payload.package_dimensions)
        )

        for (
          let fileCount = 0;
          fileCount < this.selectedFiles.length;
          fileCount++
        ) {
          formData.append('files', this.selectedFiles[fileCount].file)
        }

        this.isLoading = true;

        await this.createBlockTradeActions(formData).then((order) => {
          this.$store.commit('CREATE_ORDER_DIALOG', order.blocktrade_token)
        }).catch((error) => {
          if (error.response.status === 406) {
            this.$store.commit('CREATE_ORDER_DIALOG', false)
            this.$router.push('/register');
          }
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        this.isLoading = false
      }
    },

    closeBlockTrade() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },

    files() {
      for (
        let fileCount = 0;
        fileCount < this.$refs.file.files.length;
        fileCount++
      ) {
        if (this.$refs.file.files) {
          const ext =
            /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG)$/
          const fileName = this.$refs.file.files[fileCount].name
          const fileSize = this.$refs.file.files[fileCount].size

          if (!ext.test(fileName) || fileSize > 7000000) {
            ElMessage({
              message: this.$t('product_page.orders.pictures_allowed'),
              type: 'error',
              grouping: true,
              duration: 5000,
            })
          } else {
            const file = {
              file: this.$refs.file.files[fileCount],
              preview: URL.createObjectURL(this.$refs.file.files[fileCount]),
              name: this.$refs.file.files[fileCount].name,
            }

            this.selectedFiles.push(file)
          }
        }
      }
    },
    showDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: true,
      }
    },

    hideDeleteButton(index) {
      this.selectedFiles[index] = {
        ...this.selectedFiles[index],
        showDeleteButton: false,
      }
    },

    removeFile(index) {
      event.preventDefault()
      this.selectedFiles.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.delete-button {
  display: block;
  margin-top: 5px;
  padding: 5px;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.v-loading-spinner {
  border-radius: 52px;
}

.v-loading-spinner-inner {
  border-radius: 52px;
}

.delete-overlay img {
  width: 16px;
  height: 16px;
}

.input-line {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
}

.form-input-bloc-header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 65%;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.centered {
  text-align: center;
}

.image-preview {
  min-height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100px;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .delete-button {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }

  &:hover {
    .delete-button {
      display: block;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.package-dimensions {
  display: flex;
}

.dialog {
  z-index: 5;
  position: relative;

  .sell-order {
    background: #333333;

    .sell-order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      border-radius: 20px 20px 0px 0px;
      border-radius: 20px 20px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(244, 63, 94, 0.16) 0%,
        rgba(244, 63, 94, 0) 100%
      );
      border-bottom: 1px solid $border;

      .sell-order-header-title {
        display: flex;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 38px;
          background: #f43f5e;
          border-radius: 50%;
          margin-right: 14px;
        }
      }

      button {
        background: transparent;
        border-radius: 76px;
        border: 1px solid $border;
        background: #444;
        width: 38px;
        height: 38px;

        img {
          margin-top: 4px;
        }
      }
    }

    .sell-order-bottle {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-bottom: 15px;
      margin: 25px auto;
      margin-bottom: 10px;
      border-bottom: 1px solid $border;

      div {
        margin-left: 16px;

        h2 {
          color: white;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          border: 1px solid $border;
          color: $grey40;
          margin-top: 12px;
          border-radius: 6px;
          padding: 2px 4px;
          max-width: 120px;
          text-align: center;
        }
      }
    }

    form {
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;

      .margin-block-form {
        margin-right: 10px;
      }

      .form-input-bloc,
      .form-input-bloc-flex {
        margin-top: 18px;

        h3 {
          color: white;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }

      .form-input-bloc-flex {
        display: flex;
        align-items: center;

        h3 {
          width: 110px;
        }
      }

      .package-dimensions {
        width: 100%;

        .form-input-bloc {
          width: 23%;
        }
      }

      .sell-form-options {
        margin-top: 5px;

        h4 {
          color: $subtitle;
          font-size: 16px;
          font-weight: 600;
        }

        .sell-end-date-bloc {
          .sell-end-date-inputs {
            .sell-end-date-actions {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;

              div {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                h4 {
                  color: $subtitle;
                  font-size: 16px;
                  font-weight: 500;
                  margin-left: 8px;
                }
              }
            }

            .sell-select-end-date {
              display: flex;
              justify-content: space-between;
              margin-top: 12px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .create-order-btn {
      margin-top: 51px;
      margin-bottom: 20px !important;
      width: 194px;
      margin: auto;
    }
  }
}

.sell-order-input {
  border-radius: 5px;
  border: 1px solid $border;
}

.sell-order-input-small {
  border-radius: 5px;
  border: 1px solid $border;
  max-width: 30%;
}

.custom-file-input {
  width: 492px;
  height: 41px;
  margin-top: 13px;
  margin-bottom: 10px;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid $border;
  background: #444;
  color: $subtitle;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 492px;
  height: 41px;
}

input[type='file']::file-selector-button:hover {
  background: #444;
}

.activeType {
  background: white !important;
  color: black !important;
}

@media screen and (max-width: 768px) {
  .form-input-bloc-header {
    width: 100%;
    align-items: flex-start;
  }

  .image-preview {
    margin-right: 0;
    max-width: 80px;
  }

  .package-dimensions {
    flex-wrap: wrap;
    flex-direction: row;
    
    .form-input-bloc {
      width: 48%; 
      margin-right: 4%;

      h3 {
        margin-bottom: 3px;
      }
    }

    .form-input-bloc:nth-child(2n) {
      margin-right: 0;
    }
  }

  .custom-file-input,
  input[type='file']::file-selector-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .dialog .sell-order-header {
    align-items: flex-start;

    button {
      width: 30px;
      height: 30px;

      img {
        margin-top: 2px;
      }
    }
  }

  .dialog .sell-order-bottle {
    align-items: flex-start;
    padding: 10px;

    div {
      margin-left: 0;
    }

    p {
      max-width: 100%;
    }
  }

  .sell-form-options .sell-end-date-inputs {
    align-items: flex-start;

    .sell-end-date-actions {
      align-items: flex-start;

      div {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .sell-select-end-date {
      align-items: flex-start;
      margin-top: 10px;
    }
  }

  .create-order-btn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .form-input-bloc-header {
    width: 100%;
  }

  .dialog {
    .sell-order {
      .sell-order-bottle {
        flex-direction: row;
        padding: 5px;
        margin: -10px auto 10px auto;
      }

      form {
        padding-left: 0px;
        padding-right: 0px;
        .package-dimensions {
        .form-input-bloc {
          width: 100%;
          margin-top: 10px;
            margin-bottom: 10px;
          h3 {
            font-size: 14px;
          
          }
      }
    }

      .create-order-btn {
        margin-top: 20px;
      }

      }
    }
  }


  .image-preview {
    img {
    max-width: 60px;
    padding-right: 10px;
    }
  }

  .dialog .sell-order {
    padding: 5px;
  }

  .dialog .sell-order-header {
    padding: 8px;
  }

  .sell-form-options .sell-end-date-inputs .sell-end-date-actions,
  .sell-form-options .sell-end-date-inputs .sell-select-end-date {
    align-items: flex-start;
  }

  .sell-order-bottle div h2 {
    font-size: 16px;
  }

  .sell-order-bottle div p {
    font-size: 14px;
  }

  .sell-form-options h4 {
    font-size: 14px;
  }

  .create-order-btn {
    margin-top: 20px;
  
  }

  .package-dimensions {
    flex-wrap: initial;
  }
  .package-dimensions .form-input-bloc {
    width: 100%;
    margin-right: 2%;
  }

}
</style>