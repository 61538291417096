<template>
  <div
    v-if="!getMaxCheckProduct && getProduct"
    class="product-page"
    @click="dialogCompareBottle = false"
  >
    <div class="back" @click="$router.go(-1)">
      <img src="@/assets/svg/v2/arrowToLeft.svg" alt="Back" />
      <p>{{ $t('commons.back') }}</p>
    </div>
    <div class="product-header">
      <div class="id-title">
        <h1>{{ getProduct.title }}</h1>
      </div>
    </div>

    <div class="product-body">
      <div class="product-pictures" v-if="getProduct.files_attachments?.length > 0">
        <div class="select-picture">
          <el-image
            class="select-picture"
            @switch="getCurrentPicture"
            :src="getProduct.files_attachments[selected_miniature]"
            :preview-src-list="current_zoom_url"
            fit="contain"
            loading="eager"
          />
        </div>
        <div class="preview-picture">
          <div class="preview-picture-min">
            <div
              @click="selectedPicture(n, item)"
              :class="{ 'ex-selected': selected_miniature == n }"
              v-for="(item, n) in getProduct.files_attachments"
              :key="n"
            >
              <img :src="item" :alt="item" />
            </div>
          </div>

          <button @click="addToPortBottle" class="add-to-portfolio-button">
            <img src="@/assets/svg/v2/products/plus.svg" alt="plus" />
            {{ $t('product_page.add_to_portfolio') }}
          </button>
        </div>
      </div>
      <div class="product-pictures-empty" v-else>
        <img
          src="@/assets/svg/v2/bottle.svg"
          :alt="$t('product_page.empty_pictures')"
          width="150"
          height="150"
        />
        <h3>{{ $t('product_page.empty_pictures') }}</h3>
      </div>

      <div class="product-description">
        <div>
          <div class="product-description-header">
            <img
              src="@/assets/svg/v2/products/tag.svg"
              alt="Product description"
            />
            <h3>{{ $t('product_page.product_informations') }}</h3>
          </div>

          <div class="description" v-if="getProduct.ticker">
            <h4>Ticker :</h4>
            <p>{{ getProduct.ticker.toUpperCase() }}</p>
          </div>
          <div class="description" v-if="getProduct.ticker_composite">
            <h4>{{ $t('product_page.ticker_composite') }} :</h4>
            <a
              class="composite-ticker"
              :href="
                '/search-products?prefill=' +
                getProduct.ticker_composite +
                '&type=' +
                getProduct.code
              "
              target="_blank"
              >{{ getProduct.ticker_composite.toUpperCase() }}</a
            >
          </div>
          <div class="description" v-if="getProduct.spirit_type">
            <h4>{{ $t('product_page.spirit_type') }} :</h4>
            <p>{{ spiritTypeLabel(getProduct.ticker) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.distillery && getProduct.distillery != 0"
          >
            <h4>{{ $t('product_page.distillery') }} :</h4>
            <p>{{ capitalizeWords(getProduct.distillery) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.country && getProduct.country != 0"
          >
            <h4>{{ $t('commons.country') }} :</h4>
            <p>{{ capitalizeWords(getProduct.country) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.region && getProduct.region != 0"
          >
            <h4>{{ $t('commons.region') }} :</h4>
            <p>{{ capitalizeWords(getProduct.region) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.bottler && getProduct.bottler != 0"
          >
            <h4>{{ $t('product_page.bottler') }} :</h4>
            <p>{{ capitalizeWords(getProduct.bottler) }}</p>
          </div>
          <div class="description" v-if="getProduct.age && getProduct.age != 0">
            <h4>{{ $t('product_page.age') }} :</h4>
            <p>{{ ageType(getProduct.age) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.strength && getProduct.strength != 0"
          >
            <h4>{{ $t('product_page.strength') }} :</h4>
            <p>{{ capitalizeWords(getProduct.strength) }}%</p>
          </div>
          <div
            class="description"
            v-if="getProduct.vintage && getProduct.vintage != 0"
          >
            <h4>{{ $t('product_page.vintage') }} :</h4>
            <p>{{ capitalizeWords(getProduct.vintage) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.bottling_date && getProduct.bottling_date != 0"
          >
            <h4>{{ $t('product_page.bottling_date') }} :</h4>
            <p>{{ capitalizeWords(getProduct.bottling_date) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.bottle_size && getProduct.bottle_size != 0"
          >
            <h4>{{ $t('product_page.bottle_size') }} :</h4>
            <p>{{ getProduct.bottle_size }} ml</p>
          </div>
          <div class="description">
            <h4>{{ $t('product_page.cask_number') }} :</h4>
            <p v-if="getProduct.cask_number">
              {{ capitalizeWords(getProduct.cask_number) }}
            </p>
            <p v-else>N/A</p>
          </div>
          <div class="description" v-if="getProduct.mark">
            <h4>Mark :</h4>
            <p>{{ getProduct.mark.toUpperCase() }}</p>
          </div>
          <div
            class="description"
            v-if="
              getProduct.num_bottles_produced &&
              getProduct.num_bottles_produced != 0
            "
          >
            <h4>{{ $t('product_page.num_bottles_produced') }} :</h4>
            <p>{{ capitalizeWords(getProduct.num_bottles_produced) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.release_date"
          >
            <h4>{{ $t('product_page.release_date') }} :</h4>
            <p>{{ dateFormat(getProduct.release_date) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.release_price"
          >
            <h4>{{ $t('product_page.release_price') }} :</h4>
            <p>{{ formatNumberWithSpaces(getProduct.release_price) }}</p>
          </div>
          <div
            class="description"
            v-if="getProduct.base && getProduct.base != 0"
          >
            <h4>{{ $t('product_page.base') }} :</h4>
            <p>{{ capitalizeWords(getProduct.base) }}</p>
          </div>
          <div
            class="description"
            v-if="
              getProduct.production_method && getProduct.production_method != 0
            "
          >
            <h4>{{ $t('product_page.production_method') }} :</h4>
            <p>{{ capitalizeWords(getProduct.production_method) }}</p>
          </div>
          <div
            class="description"
            v-if="
              getProduct.distillery_status &&
              getProduct.distillery_status != null
            "
          >
            <h4>{{ $t('product_page.distillery_status') }} :</h4>
            <p v-if="getProduct.distillery_status == 1">
              {{ $t('product_page.active') }}
            </p>
            <p v-else>
              {{ $t('product_page.closed') }}
            </p>
          </div>
        </div>

        <div class="error-bottle-container">
          <div @click="dialogReportBottle = true" class="error-bottle">
            <img
              src="@/assets/svg/v2/products/bottleError.svg"
              alt="Report error (bottle)"
              width="17"
            />
            <p>{{ $t('commons.report_error') }}</p>
          </div>
        </div>
      </div>
    </div>

    <CommonModal
      v-if="addToPortfolioModal"
      size="small"
      :modal-title="$t('product_page.add_to_portfolio')"
      @close="closeModal"
    >
      <SecondStepForm
        :product-to-add="getProduct"
        @close-modal="closeModal"
        noTrash
      />
    </CommonModal>

    <div class="product-history" id="product-history">
      <div class="trading-kyc-empty">
        <h2>{{ $t('product_page.live_orders') }}</h2>
        <div v-if="registerStep < 3" class="contain-kyc-description">
          <div class="kyc-empty-description">
            <img src="@/assets/svg/v2/infos.svg" alt="Informations" />
            <p>
              {{ $t('product_page.orders.kyc_not_verified') }}
            </p>
          </div>
          <div class="explore" @click="$router.push('/register')">
            <h3>{{ $t('product_page.orders.kyc_redirect') }}</h3>
          </div>
        </div>
      </div>

      <div class="product-orders-choice">
        <button
          :class="{ isSelected: select_order_type_see === 1 }"
          class="select-order-button"
          @click="selectOrderTypeSee(1)"
        >
          <img
            v-if="select_order_type_see === 1"
            src="@/assets/svg/v2/products/buySellActive.svg"
            alt="Buy & Sell orders"
          />
          <img
            v-else
            src="@/assets/svg/v2/products/buySell.svg"
            alt="Buy & Sell orders"
          />
          <p class="button-text">{{ $t('product_page.btn_buy_sell') }}</p>
          <p class="orders-number" v-if="getOrders.length !== 0">
            {{
              getOrders.filter(
                (order) => order.order_type === 1 || order.order_type === 0
              ).length
            }}
          </p>
        </button>
        <button
          :class="{ isSelected: select_order_type_see === 3 }"
          class="select-order-button v3-disabled"
          @click="selectOrderTypeSee(3)"
        >
          <img
            v-if="select_order_type_see === 3"
            src="@/assets/svg/v2/products/swapActive.svg"
            alt="Swap orders"
          />
          <img
            v-else
            src="@/assets/svg/v2/products/swap.svg"
            alt="Swap orders"
          />
          <p class="button-text">{{ $t('product_page.btn_swap') }}</p>
          <p class="orders-number" v-if="getOrders.length !== 0">
            {{
              getOrders.filter(
                (order) => order.order_type === 2 || order.order_type === 3
              ).length
            }}
          </p>
        </button>
        <button
          :class="{ isSelected: select_order_type_see === 4 }"
          class="select-order-button"
          @click="selectOrderTypeSee(4)"
        >
          <img
            src="@/assets/svg/v2/products/blocktrade.svg"
            alt="Block Trade"
          />
          <p class="button-text">{{ $t('product_page.btn_blocktrade') }}</p>
        </button>
      </div>

      <div class="product-orders" id="orders">
        <Orders
          v-if="select_order_type_see !== 3"
          :orderType="select_order_type_see"
          @checkCreateOrderAgree="openCreateOrderDialog"
        ></Orders>
      </div>
      <swapTable
        v-if="select_order_type_see === 3"
        @createSwapOrder="openCreateSwapDialog"
      />

      <div class="product-history-filters">
        <hsLineChart
          ref="hsLineChart"
          v-if="type_of_chart === 0"
          :type_of_chart="type_of_chart"
          @type_of_chart="selectTypeOfChart"
          :reset="propsResetHCLine"
          :dialogCompareBottle="dialogCompareBottle"
          :spirittype="getProduct.code"
          @dialogCompare="setDialogCompare"
          @fees="setWithFees"
          @currency="setCurrency"
          @tagPriceHistory="addPriceHistory"
        ></hsLineChart>
        <hsCompositeChart
          v-if="type_of_chart === 1"
          :type_of_chart="type_of_chart"
          @type_of_chart="selectTypeOfChart"
          :reset="propsResetHCLine"
          :dialogCompareBottle="dialogCompareBottle"
          :spirittype="getProduct.code"
          @dialogCompare="setDialogCompare"
          @fees="setWithFees"
          @currency="setCurrency"
          @tagPriceHistory="addPriceHistory"
        ></hsCompositeChart>
      </div>
    </div>
    <div class="infos-data-chart">
      <img
        src="@/assets/svg/v2/products/chartInfos.svg"
        alt="Chart informations"
      />
      <div class="infos-data-chart-sources">
        <div>
          <p>{{ $t('product_page.price_history.data_info_1') }}</p>
          <p>
            <strong
              >{{ getPriceHistoryInfos?.total_points_without_delay }}
              {{ $t('product_page.price_history.data_info_11') }}</strong
            >
          </p>
          <p>{{ $t('product_page.price_history.data_info_2') }}</p>
          <p>
            <strong
              >{{ getPriceHistoryInfos?.sources }}
              {{ $t('product_page.price_history.data_info_3') }}</strong
            >
          </p>
        </div>
        <p>{{ $t('product_page.price_history.responsability') }}</p>
      </div>
    </div>
    <div>
      <CommonModal
        v-if="dialogFirstTime"
        size="small"
        :modal-title="
          $t('product_page.orders.ft_title') +
          ' : ' +
          (firstTimeType === 'buy' ? $t('commons.buyer') : $t('commons.seller'))
        "
        @close="closeModal"
        headerColor="type === 'buy' ? 'rgba(132, 204, 22, 0.16)' : '#F43F5E30'"
      >
        <firstTimeCreateOrder
          :type="firstTimeType"
          @agree="openDialogCreateOrder"
        />
      </CommonModal>
      <CommonModal
        v-if="getCreateOrderDialog === 'buy'"
        size="small"
        :modal-title="$t('product_page.orders.create_buy_order')"
        @close="closeModal"
        headerColor="#84CC1659"
      >
        <createBuyOrder_V2 />
      </CommonModal>
      <CommonModal
        v-if="
          getCreateOrderDialog === 'giver' || getCreateOrderDialog === 'taker'
        "
        size="small"
        :modal-title="swapModalTitle"
        @close="closeModal"
        :headerColor="
          getCreateOrderDialog === 'giver' ? '#2667ff59' : '#a855f79f'
        "
      >
        <createSwapOrder
          :product_type="getCreateOrderDialog === 'giver' ? 2 : 3"
        />
      </CommonModal>
      <CommonModal
        v-if="getCreateOrderDialog === 'sell'"
        size="small"
        height="content"
        :modal-title="$t('product_page.orders.create_sell_order')"
        @close="closeModal"
        headerColor="#F43F5E59"
      >
        <createSellOrder_V2 />
      </CommonModal>
      <CommonModal
        v-if="getCreateOrderDialog === 'blocktrade'"
        :modal-title="$t('product_page.orders.create_blocktrade_order')"
        size="small"
        @close="closeModal"
        headerColor="#2667ff73"
      >
        <createBlockTrade />
      </CommonModal>
      <CommonModal
        v-if="getCreateOrderDialog && getCreateOrderDialog.length === 200"
        headerColor="#2667ff73"
        size="small"
        modal-title="Create a block trade"
        @close="closeModal"
      >
        <blockTrade
          :type="getProduct.code"
          :productName="getProduct.title"
          :reference="getProduct.isbn"
          :ticker="getProduct.ticker"
        />
      </CommonModal>
      <CommonModal
        v-if="dialogReportBottle"
        :modal-title="$t('product_page.report.title')"
        size="small"
        @close="closeReport()"
      >
        <reportBottleError @close="closeReport()" />
      </CommonModal>
    </div>
  </div>
</template>

<script>
import Orders from '@/components/modules/orders.vue'
import hsCompositeChart from '@/components/modules/charts/hcComposite.vue'
import hsLineChart from '@/components/modules/charts/hcLine.vue'
import m from 'moment';
import { mapActions, mapGetters } from 'vuex'
import firstTimeCreateOrder from '@/components/modules/dialogs/firstTimeCreateOrder.vue'
import createBuyOrder_V2 from '@/components/modules/dialogs/createBuyOrder_V2.vue'
import createSellOrder_V2 from '@/components/modules/dialogs/createSellOrder_V2.vue'
import reportBottleError from '@/components/modules/dialogs/reportBottleError.vue'
import createBlockTrade from '@/components/modules/dialogs/createBlockTrade.vue'
import blockTrade from '@/components/modules/dialogs/blockTrade'
import CommonModal from '@/components/commons/modal.vue'
import SecondStepForm from '@/components/modules/portfolio/createBottleForm/secondStepForm.vue'
import createSwapOrder from '@/components/modules/dialogs/createSwapOrder.vue'
import swapTable from '@/components/modules/swapTable.vue'

export default {
  components: {
    Orders,
    hsCompositeChart,
    hsLineChart,
    firstTimeCreateOrder,
    createBuyOrder_V2,
    createSellOrder_V2,
    reportBottleError,
    createBlockTrade,
    blockTrade,
    CommonModal,
    createSwapOrder,
    swapTable,
    SecondStepForm,
  },
  data() {
    return {
      create_order: false,
      select_order_type_see: 1,
      selectCurrency: 'EUR',
      time: 'daily',
      type_of_chart: 0,
      withFees: 1,
      salesNumber: null,
      sourcesNumber: null,

      propsResetHCLine: 0,

      selected_miniature: 0,
      current_zoom_url: [],

      dialogFirstTime: false,
      firstTimeType: 'buy',
      dialogReportBottle: false,
      dialogCompareBottle: false,
      addToPortfolioModal: false,
      spirit_type: 14,
    }
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      getOrder: 'getOrder',
      getOrders: 'getOrders',
      getAuthStatus: 'getIsAuth',
      getCurrency: 'getCurrency',
      getPriceHistory: 'getPriceHistory',
      getPriceHistoryInfos: 'getPriceHistoryInfos',
      getLists: 'getLists',
      getTags: 'getTags',
      getUserDetails: 'getUserDetails',
      getMaxCheckProduct: 'getMaxCheckProduct',
      getCreateOrderDialog: 'getCreateOrderDialog',
      registerStep: 'registerStep'
    }),
    swapModalTitle() {
      return (
        'Create a swap ' +
        (this.getCreateOrderDialog === 'giver' ? 'giver' : 'taker') +
        ' order'
      )
    },
  },
  watch: {
    getProduct() {
      const isbn = /^[0-9a-zA-Z]{11,15}$/

      if (isbn.test(this.$route.params.isbn)) {
        if (this.$route.query.tab) {
          this.selectOrderTypeSee(parseInt(this.$route.query.tab))
        } else {
          this.selectOrderTypeSee(1)
        }
        this.priceHistory()
      }

      if (this.getProduct?.files_attachments?.length > 0) {
        this.current_zoom_url = []
        this.getProduct.files_attachments.forEach((picture) => {
          this.current_zoom_url.push(picture)
        })
      }
    },
    async selectCurrency(nV) {
      if (nV !== null) {
        this.$store.commit('CURRENCY', nV)
        this.priceHistory()
        await this.getTags.forEach(async (tag) => {
          await this.$refs.hsLineChart.removeTag(tag, false)
          await this.addPriceHistory(tag)
        })
      }
    },
    withFees() {
      this.priceHistory()
      this.getTags.forEach(async (tag) => {
        await this.$refs.hsLineChart.removeTag(tag, false)
        this.addPriceHistory(tag)
      })
    },
  },
  created() {
    this.$store.commit('PRODUCT', []);
    setTimeout(() => {
      if (!this.getAuthStatus === true) {
        this.$router.push('/login');
      }
    }, 1000);
  },
  mounted() {
    this.getFolders()
    this.getSettings()
    const isbn = /^[0-9a-zA-Z]{11,15}$/
    const payload = {
      isbn: this.$route.params.isbn,
      currency: this.getCurrency,
      with_fees: this.withFees,
      ticker_composite: this.getProduct.ticker_composite,
    }

    this.product(payload);

    if (!isbn.test(payload.isbn)) {
      this.$router.go(-1)
    } else {

      if (this.$route.query.tab) {
        this.selectOrderTypeSee(Number(parseInt(this.$route.query.tab)))
        const element = document.getElementById('product-history')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      priceHistoryAuth: 'priceHistoryAuth',
      autoSearchAuth: 'autocompleteSearchAuth',
      product: 'product',
      getFolders: 'getFolders',
      getSettings: 'getSettings',
    }),

    capitalizeWords(str) {      
      return str.replace(/\b(\p{L})(\p{L}*'?\p{L}*)/gu, function(match, p1, p2) {
        return p1.toUpperCase() + p2;
      });
    },
    closeModal() {
      this.dialogFirstTime = false
      this.addToPortfolioModal = false
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    spiritTypeLabel(ticker) {
      const base = ticker.slice(0, 2)
      let typeLabel = null

      switch (base) {
        case 'AR':
          typeLabel = 'Armagnac'
          break
        case 'AW':
          typeLabel = 'American Whisky'
          break
        case 'CO':
          typeLabel = 'Cognac'
          break
        case 'RH':
          typeLabel = 'Rhum'
          break
        case 'RU':
          typeLabel = 'Rum'
          break
        case 'WH':
          typeLabel = 'Whisky'
          break
        case 'LQ':
          typeLabel = 'Liqueur'
          break
        case 'TQ':
          typeLabel = 'Tequila'
          break
        default:
          typeLabel = 'N/A'
          break
      }

      return typeLabel
    },
    ageType(age) {
      if (age.indexOf('M') !== -1 || !/\d/.test(age)) {
        return `${age}`
      }
      return `${age}Y`
    },
    priceHistory() {
      if (this.getProduct.isbn !== null) {
        const payload = {
          isbn: this.getProduct.isbn,
          currency: this.getCurrency,
          with_fees: this.withFees,
          ticker_composite: this.getProduct.ticker_composite,
        }

        this.$store.dispatch('priceHistoryAuth', payload)

        if (this.getProduct.ticker_composite) {
          this.$store.dispatch('compositePriceHistory', payload)
        }
      }
    },
    openCreateOrderDialog(type) {
      this.firstTimeType = type

      if (type === 'buy') {
        if (!this.getUserDetails?.agree.buy) {
          this.dialogFirstTime = true
        } else {
          this.$store.commit('CREATE_ORDER_DIALOG', 'buy')
        }
      } else if (type === 'sell') {
        if (!this.getUserDetails?.agree.sell) {
          this.dialogFirstTime = true
        } else {
          this.$store.commit('CREATE_ORDER_DIALOG', 'sell')
        }
      } else if (type === 'blocktrade') {
        if (!this.getUserDetails?.agree.blocktrade) {
          this.dialogFirstTime = true
        }
        this.$store.commit('CREATE_ORDER_DIALOG', 'blocktrade')
      }
    },
    selectTypeOfChart(type) {
      this.type_of_chart = type
      this.propsResetHCLine++
      this.priceHistory()
    },
    selectOrderTypeSee(type) {
      this.$store.commit('ORDER', [])
      const url = new URL(window.location.href)
      const params = url.searchParams

      window.history.replaceState({}, '', url)
      this.$router.addRoute({ path: url.pathname, query: params })

      if (type == 4) {
        if (!this.getUserDetails?.agree.sell) {
          this.dialogFirstTime = true
        } else {
          this.$store.commit('CREATE_ORDER_DIALOG', 'blocktrade')
        }
      } else {
        const getProductISBN = this.$route.params.isbn

        this.$store.dispatch('getOrders', getProductISBN)
        this.select_order_type_see = type
      }
    },
    getCurrentPicture(selected) {
      this.selected_miniature = selected
    },
    selectedPicture(selected, url) {
      this.current_zoom_url.splice(0, 0, url)
      this.selected_miniature = selected
    },
    async addPriceHistory(product) {
      if (this.getProduct.isbn !== null) {
        const payload = {
          is_tag: true,
          isbn: product.isbn,
          ticker: product.name,
          name: product.name,
          color: product.color,
          currency: this.getCurrency,
          with_fees: this.withFees,
          ticker_composite: this.getProduct.ticker_composite,
          title: product.title,
        }

        await this.$store.dispatch('priceHistoryAuth', payload)

        if (this.getProduct.ticker_composite) {
          await this.$store.dispatch('compositePriceHistory', payload)
        }
      }
    },
    dateFormat(date) {
      return m(date).format('DD/MM/YYYY');
    },
    formatNumberWithSpaces(number) {
      if (!number) return null
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
    closeReport() {
      this.dialogReportBottle = false
    },
    setWithFees(fees) {
      if (fees === true || fees === 1) {
        this.withFees = 1
      } else {
        this.withFees = 0
      }
    },
    setCurrency(currency) {
      this.selectCurrency = currency
      this.$store.commit('CURRENCY', currency)
    },
    setDialogCompare(state) {
      this.dialogCompareBottle = state
    },
    openCreateSwapDialog(type) {
      this.$store.commit('CREATE_ORDER_DIALOG', type === 2 ? 'giver' : 'taker')
    },
    addToPortBottle() {
      this.addToPortfolioModal = true
    },
    openDialogCreateOrder(type) {
      this.dialogFirstTime = false
      this.openCreateOrderDialog(type)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';
.product-header,
.id-title,
.identification,
.product-body,
.description,
.product-orders-choice,
.product-orders-choice button,
.product-history-filters,
.select-picture,
.preview-picture,
.preview-picture-min,
.create-order,
.infos-data-chart,
.back,
.product-description-header,
.error-bottle,
.product-history-infos-bloc,
.error-bottle {
  display: flex;
}

.select-order-button {
  display: flex;
  max-width: 300px;
}
.product-page {
  max-width: 1300px;
  margin: auto;
  padding: 0 15px;
}

.button-order-title {
  justify-content: center;
  align-items: center;
}

.product-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .id-title {
    margin-top: -20px;
    font-weight: 700;

    h1 {
      text-align: center;
    }

    .identification {
      margin-top: 10px;

      .identification-code {
        cursor: pointer;
        color: $grey40;
        font-weight: 500;
        font-size: 14px;
        padding: 3px 10px;
        border: 1px solid $grey40;
        border-radius: 6px;
      }
    }
  }
}

.product-body {
  justify-content: space-between;
  margin: 20px 0;

  .product-pictures,
  .product-description,
  .product-pictures-empty {
    max-width: 48%;
  }

  .product-description {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid $border;
    background: linear-gradient(
      180deg,
      rgba(38, 103, 255, 0.16) 0%,
      rgba(38, 103, 255, 0) 100%
    );
    width: 630px;
    height: 628px;
    padding: 20px;
    padding-bottom: 5px;
    padding-right: 10px;
    overflow: auto;

    .product-description-header {
      align-items: center;

      h3 {
        color: white;
        font-size: 18px;
        font-weight: 600;
        margin-left: 12px;
      }
    }

    .description {
      margin: 15px 0;

      h4 {
        color: $subtitle;
        font-weight: 500;
        font-size: 16px;
      }

      p {
        margin-left: 10px;
        color: white;
      }

      .composite-ticker {
        margin-left: 10px;
        color: $blue;
      }
    }
  }

  .product-pictures,
  .product-pictures-empty {
    height: 550px;
    width: 100%;

    .select-picture {
      background: white;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      img {
        max-height: 400px;
        opacity: 1!important;
      }
    }

    .preview-picture {
      justify-content: left;
      align-items: flex-end;
      overflow-x: hidden;
      overflow-y: hidden;
      background: linear-gradient(
        180deg,
        rgba(38, 103, 255, 0.16) 0%,
        rgba(38, 103, 255, 0.034) 100%
      );
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 3px;

      .add-to-portfolio-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 8px;
        width: 100%;
        max-width: 185px;
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        border: none;
        background-color: rgba(160, 160, 160, 0.196);
        margin-bottom: 8px;
        margin-right: 5px;
      }

      .preview-picture-min {
        width: 100%;
      }

      .preview-picture-min div {
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 85px;
        height: 85px;
        border-radius: 12px;
        border: 2px solid rgb(240, 240, 240);
        background-color: white;
        margin: 6px;
        overflow: hidden;

        img {
          width: 80px;
          height: 80px;
        }
      }
    }
  }

  .product-pictures-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      color: $subtitle;
      font-size: 15px;
      font-weight: 400;
      margin-top: 30px;
    }
  }
}

.trading-kyc-empty {
  display: flex;
  align-items: center;
  color: #f6ff00;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  img {
    margin-right: 8px;
  }

  .explore {
    display: flex;
    cursor: pointer;
    margin-left: 10px;

    h3 {
      color: white;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.error-bottle-container {
  display: flex;
  justify-content: right;

  .error-bottle {
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    border: none;
    background-color: rgba(160, 160, 160, 0.196);
    padding: 4px 8px;

    img {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
}

.product-history-infos-bloc {
  margin-top: 25px;
}

.orders-number {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
  padding: 2px 10px 2px 10px;
  border-radius: 32px;
  background-image: linear-gradient(to bottom, #646464, #9797971e);
}

.preview-picture::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.preview-picture::-webkit-scrollbar-track {
  background: #fff;
}

.preview-picture::-webkit-scrollbar-thumb {
  background-color: #d1dddd;
  border-radius: 12px;
}

.preview-picture::-webkit-scrollbar-thumb:hover {
  background-color: $base-color;
}

.ex-selected {
  border: 2px solid $blue !important;
}

.product-history {
  h2 {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }

  .product-history-filters {
    flex-direction: column;

    h2 {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
}

.product-orders-choice {
  margin-bottom: 15px;
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  padding: 6px;
  align-items: center;

  button {
    justify-content: center;
    border: 0;
    padding: 8px 12px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 15px;
    background-color: transparent;
    color: $subtitle;

    img {
      margin-right: 5px;
    }
  }
}

.create-order {
  align-items: center;
  justify-content: center;
  border: 1px solid $base-color !important;

  &:hover {
    background-color: $ligh-color;
  }

  span {
    margin-left: 5px;
  }
}

.infos-data-chart {
  color: $subtitle;
  margin-bottom: 76px;
  align-items: start;

  .infos-data-chart-sources {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    div {
      display: flex;

      p {
        font-weight: 400;
        color: $subtitle;

        strong {
          margin: 0 4px;
        }
      }
    }

    p {
      font-weight: 400;
    }
  }
}

.back {
  cursor: pointer;
  color: $subtitle;
  font-size: 15px;
  margin-right: 150px;
  margin-top: 20px;
}

@media screen and (max-width: 1350px) {
  .product-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 70px;

    .product-description-report {
      width: 100%;

      .product-description {
        margin-left: 0;
        margin-top: 24px;
        justify-content: center;
        width: 100%;
        padding: 0;

        .product-description-header {
          padding-top: 20px;
        }

        .description,
        .product-description-header {
          padding-left: 20px;
        }
      }
    }

    .error-bottle {
      justify-content: flex-end;
    }

    .product-pictures {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-bottom: 45px;

      .preview-picture {
        overflow: visible;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .product-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 70px;

    .product-description-report {
      width: 100%;

      .product-description {
        margin-top: 40px;
        justify-content: center;
        width: 100%;
        padding: 0;

        .product-description-header {
          padding-top: 20px;
        }

        .description,
        .product-description-header {
          padding-left: 20px;
        }
      }
    }

    .error-bottle {
      justify-content: flex-end;
    }

    .product-pictures {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-bottom: 45px;

      .preview-picture {
        overflow: visible;
        padding: 3px 0;
      }
    }
  }

  .product-body {
    div {
      width: 100%;

      .product-description {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
        padding: 20px 0;

        .product-description-header,
        .description {
          margin-left: 20px;
          max-width: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .product-orders-choice {
    margin: auto;
    margin-bottom: 10px;
    border-radius: 0;
    border: 0;
  }

  .product-page {
    padding: 0;

    .back {
      padding: 10px;
      margin-bottom: 20px;
    }

    .product-body {
      margin-top: 0px;
      margin-bottom: 0;
      width: 100%;

      .product-pictures {
        max-width: none;
        margin-bottom: 0;
        margin-top: 20px;

        .preview-picture {
          align-items: flex-start;
          
          .add-to-portfolio-button {
            margin-left: 8px;
          }
        }

        .select-picture,
        .preview-picture {
          border-radius: 0;
        }
      }
    }
  }

  .trading-kyc-empty {
    flex-direction: column;
    align-items: flex-start;

    .contain-kyc-description {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 8px;
    }

    .kyc-empty-description {
      display: flex;
      margin-left: 0;
    }

    .explore {
      margin-left: 25px;
      margin-top: 4px;
    }
  }

  .product-description {
    max-width: none !important;
    width: 100% !important;
    margin-top: 0px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-radius: 0 !important;
    border: 0 !important;
    margin-bottom: 10px;

    div > .description,
    div > .product-description-header {
      margin-left: 10px !important;
    }

    div:nth-child(1) {
      overflow: hidden;
    }
  }

  .product-pictures,
  .product-pictures-empty {
    height: auto !important;
  }

  .product-pictures-empty {
    width: auto !important;
    margin: 20px auto;

    h3 {
      text-align: center;
    }
  }

  .product-description-report {
    .product-description {
      border-radius: 0;
      border: 0;
    }

    .error-bottle {
      margin-left: 10px;
      margin-top: 0;
    }
  }

  .product-history-filters {
    h2 {
      margin-left: 10px;
    }
  }

  .infos-data-chart {
    max-width: none;
    padding: 0 5px;
  }

  .infos-data-chart-sources {
    div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
    }
  }

  .error-bottle-container {
    max-width: 200px;
    justify-content: flex-start;

    .error-bottle {
      margin-left: 10px !important;
      width: auto;
    }
  }

  .preview-picture {
    display: flex;
    flex-direction: column;
  }

  .product-history {
    h2 {
      margin-left: 10px;
    }
  }
}
</style>
