<template>
  <div class="swap-table">
    <div class="swap-table-content">
      <div class="taker-table">
        <div class="header-taker">
          <div class="trade-for">
            <div class="title">
              <img
                class="icon-swap-taker"
                alt="Sell order"
                height="24"
                src="@/assets/svg/v2/products/swap.svg"
                width="24"
              />
              <h3 class="table-name">{{  $t('orders.swap_taker') }}</h3>
            </div>
            <div class="button-create-order" @click="createSwapOrder(3)">
              <img
                alt="Add ask order"
                src="@/assets/svg/v2/products/plus.svg"
              />
              {{ $t('product_page.orders.create_order') }}
            </div>
          </div>
        </div>
        <div class="column-header-taker">
          <p class="column-name">{{  $t('orders.account_type') }}</p>
          <p class="column-name">{{  $t('orders.swap_giver') }}</p>
        </div>
        <div
          class="swap-line"
          v-for="order in getTakerOrders()"
          :key="order.order_uuid"
          @click="selectOrder(1, order)"
        >
          <div class="account-type">
            <img
              v-if="order.user.type === 1"
              alt="Pro account"
              src="@/assets/svg/v2/pro.svg"
            />
            <img
              v-else
              alt="Individual account"
              src="@/assets/svg/v2/personnal.svg"
            />
            <p>{{ order.user.type === 1 ? 'Pro' : 'Individuel' }}</p>
          </div>
          <p class="bottle-name" v-text-length-adjuster>
            {{ order.swap_title }}
          </p>
        </div>
        <div v-if="getTakerOrders().length === 0" class="empty-orders">
          {{ $t('product_page.orders.empty') }}
        </div>
      </div>
      <div class="giver-table">
        <div class="header-giver">
          <div class="trade-for">
            <div class="title">
              <img
                class="icon-swap-giver"
                alt="Sell order"
                height="24"
                src="@/assets/svg/v2/products/swap.svg"
                width="24"
              />
              <h3 class="table-name">{{  $t('orders.swap_giver') }}</h3>
            </div>
            <div class="button-create-order" @click="createSwapOrder(2)">
              <img
                alt="Add ask order"
                src="@/assets/svg/v2/products/plus.svg"
              />
              {{ $t('product_page.orders.create_order') }}
            </div>
          </div>
        </div>
        <div class="column-header-giver">
          <p class="column-name">{{  $t('orders.account_type') }}</p>
          <p class="column-name">{{  $t('orders.swap_taker') }}</p>
        </div>
        <div
          class="swap-line"
          v-for="order in getGiverOrders()"
          @click="selectOrder(1, order)"
          :key="order.order_uuid"
        >
          <div class="account-type">
            <img
              v-if="order.user.type === 1"
              alt="Pro account"
              src="@/assets/svg/v2/pro.svg"
            />
            <img
              v-else
              alt="Individual account"
              src="@/assets/svg/v2/personnal.svg"
            />
            <p>{{ order.user.type === 1 ? 'Pro' : 'Individuel' }}</p>
          </div>
          <p class="bottle-name" v-text-length-adjuster>
            {{ order.swap_title }}
          </p>
        </div>
        <div v-if="getGiverOrders().length === 0" class="empty-orders">
          {{ $t('product_page.orders.empty') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SwapTable',
  directives: {
    'text-length-adjuster': {
      inserted(el) {
        const textLength = el.textContent.length
        const maxSize = 24
        const minSize = 12
        let fontSize = maxSize - textLength
        fontSize = Math.max(fontSize, minSize)
        fontSize = Math.min(fontSize, maxSize)
        el.style.fontSize = fontSize + 'px'
      },
    },
  },
  data() {
    return {
      search: '',
      orders: [],
    }
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      getOrders: 'getOrders',
      getOrder: 'getOrder',
      getAuthStatus: 'getIsAuth',
    }),
  },
  methods: {
    ...mapActions({
      getOrderActions: 'getOrder',
    }),
    async selectOrder(numbers, order) {
      if (!order.is_pretrade) {
        order.numbers = numbers
        if (order.isbn) {
          await this.getOrderActions(order).then(() => {
            this.$router.push('/product/order/informations')
          })
        }
      }
    },
    getGiverOrders() {
      const giverOrders = this.getOrders.filter(
        (order) => order.order_type === 2 && order.isbn === this.getProduct.isbn
      )
      return giverOrders
    },
    createSwapOrder(orderType) {
      this.$emit('createSwapOrder', orderType)
    },
    getTakerOrders() {
      return this.getOrders.filter(
        (order) => order.order_type === 3 && order.isbn === this.getProduct.isbn
      )
    },
    ...mapActions({
      getOrderActions: 'getOrder',
    }),
  },
  async mounted() {
    await this.getOrderActions()

    this.orders = this.getOrders
    this.adjustTextSize()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.swap-table {
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border: 1px solid $border;
  border-radius: 12px;
  width: 1300px;
}

.empty-orders {
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  color: $grey40;
  font-size: 16px;
  font-weight: 500;
  max-width: 218px;
}


.header-giver {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 12px 0 0;
  background-image: linear-gradient(
    to bottom,
    rgba(38, 103, 255, 0.35),
    rgba(38, 103, 255, 0)
  );
}
.header-taker {
  width: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    rgba(168, 85, 247, 0.35),
    rgba(168, 85, 247, 0)
  );
  border-radius: 12px 0 0 0;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.swap-table-content {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: max-content;
  justify-content: center;
  border-radius: 0 0 12px 12px;
}

.giver-table {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  background-color: #333;
  height: 100%;
  border-radius: 0 12px 12px 0;

  border-left: 1px solid $border;
}

.taker-table {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $border;
}

.column-header-taker {
  display: flex;
  width: 97%;
  padding: 10px;
  gap: 165px;
  border-bottom: 2px solid rgba(168, 85, 247, 0.35);
}

.column-header-giver {
  display: flex;
  width: 97%;
  gap: 165px;
  padding: 10px;
  border-bottom: 2px solid rgba(38, 103, 255, 0.35);
}

.bottle-name {
  color: #fff;
  font-size: 15px;
  max-width: 400px;
  justify-content: flex-end;
}

.swap-line {
  display: flex;
  width: 90%;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: $ligh-grey;
  }
}

.account-type {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 103px;
  text-align: center;
  flex-direction: row;
  color: grey;
  border-radius: 6px;
  border: 1px solid $border;
  font-size: 14px;
  max-height: 20px;
  margin-right: 80px;
}

.swap-title {
  color: #fff;
  font-size: 20px;
}

.trade-for {
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.trade-for h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.trade-for p {
  color: grey;
  font-size: 14px;
}

.column-name {
  color: $subtitle;
  font-size: 16px;
  font-weight: 500;
}

.button-create-order {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  margin: 5px;
  border-radius: 6px;
  background-color: rgba(160, 160, 160, 0.196);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-right: 24px;
  img {
    margin-right: 3px;
  }
}

.icon-swap-giver {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 13px;
  background-color: $blue;
}

.icon-swap-taker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 13px;
  background-color: #a855f7;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
